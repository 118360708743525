import React from 'react';
import {NavLink} from "react-router-dom";
import {Button, Icon} from "antd";

function Homepage() {

    return (
        <div>
            <h1>Services Directory</h1>
            <p><a href="https://www.dih-hero.eu">DIH-HERO</a> is a pan-European platform aimed at accelerating the EU
                healthcare robotics sector. DIH-HERO is focused on helping small and medium-sized enterprises (SMEs), in
                particular; deliver impactful healthcare robotics products and solutions across the EU and the world.
            </p>
            <p>DIH-HERO offers companies involved in all topics related to healthcare robotics, funding opportunities
                to demonstrate and trial their solutions as well as access to technical and business services to help
                speed up the go-to-market process.</p>
            <p>A broad EU-wide partner network will offer innovation connections, initiated by seventeen leading
                research organisations and universities who are core members of this project.</p>

            <p>This service directory helps you identify the different services offered by companies in the DIH-HERO
                network that could add value in the creation of novel healthcare robotics solutions.</p>


            <div>
            <NavLink to="/directory"><Button type="primary" size="large" style={{marginRight: 24}}><Icon type="read" /> Service
                Directory</Button></NavLink>

            <NavLink to="/start"><Button type="secondary" size="large"><Icon type="plus-circle" /> Register your
                service</Button></NavLink>
            </div>
            <div style={{marginTop:24}}>
                <NavLink to="/standards"><Button type="primary" size="large" style={{marginRight: 24}}><Icon type="read" /> Standards
                    Directory</Button></NavLink>
            </div>
        </div>
    );

}

export {Homepage};