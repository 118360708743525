import React, {Component} from 'react';
import './form.css';

import {Form, Button, Input, Radio, Typography, Alert} from 'antd';

import serviceCatalog from './service-catalog.json';
import surveyCatalog from './survey.json';
import {registerService} from "./api";

const {Text} = Typography;
const {TextArea} = Input;
const RadioGroup = Radio.Group;


class RegisterService extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            errorDescription: null,
            submitting: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const organisationId = this.props.match.params.id;

        console.log('here');
        this.props.form.validateFields((error, value) => {

            console.log('formsubmit', error, value);

            if (error === null) {

                const service = {
                    ...value,
                    "organisation_id": organisationId,
                };


                if (service.financialmodelOther) {
                    service.financialmodel = `other[${service.financialmodelOther}]`
                    service.financialmodelOther = undefined;
                }

                if (service.location === "city" || service.location === "province" || service.location === "other") {
                    service.location = `${service.location}[${service.locationSpecific}]`

                }

                this.setState({submitting: true})
                registerService(service)
                    .then(registeredOrg => {
                        this.props.history.push(`/organisation/${organisationId}/`);
                        this.setState({submitting: false});
                    }).catch((err) => {
                    this.setState({
                        error: "Could not register your answers",
                        errorDescription: err.toString(),
                        submitting: false
                    })
                });

            }
        });
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const {error, errorDescription, submitting} = this.state;

        const offer_category = this.props.form.getFieldValue('offer_category');
        const offer_id = this.props.form.getFieldValue('offer_id');
        const financialmodel = this.props.form.getFieldValue('financialmodel');
        const location = this.props.form.getFieldValue('location');
        const isEuproject = this.props.form.getFieldValue('is_euproject');
        return (
            <div>
                <h1>Register A Service</h1>


                <Form style={{marginTop: 32}} layout="vertical" onSubmit={this.handleSubmit}>

                    <h2>Service Category</h2>
                    <Form.Item label="Please choose the service category that best describes the services offered by your
                            organisation:">
                        {getFieldDecorator('offer_category', {
                            rules: [{required: true, message: "Please select the service area of this service"}]
                        })(
                            <RadioGroup>
                                {Object.entries(serviceCatalog)
                                    .map(([key, value]) => {
                                        return (
                                            <div style={{marginBottom: 12}}><Radio key={key}
                                                                                   value={key}><strong>{value.name}</strong>
                                            </Radio>
                                                <p style={{marginLeft: 24, marginTop: 6}}>{value.description}</p>

                                            </div>)
                                    })}
                            </RadioGroup>)}
                    </Form.Item>

                    <Form.Item label="Please select the area of the service that you want to register:">

                        {offer_category ?

                            getFieldDecorator('offer_id', {
                                rules: [{required: true, message: "Please select a service to register"}]
                            })(
                                <RadioGroup>
                                    {Object.entries(serviceCatalog[offer_category].services)
                                        .map(([key, value]) => {
                                            return (
                                                <div style={{marginBottom: 12}}>
                                                    <Radio key={key} value={key}>
                                                        <strong>{value.name}</strong>
                                                    </Radio>
                                                    <p style={{marginLeft: 24, marginTop: 6}}>{value.description}</p>
                                                </div>)
                                        })}
                                </RadioGroup>)


                            : (<Text disabled>Please select a service category area to continue</Text>)}

                    </Form.Item>


                    <h2>Service Info</h2>

                    {offer_id ? (
                        <div>

                            <Form.Item label="Provide a brief description of your service:">
                                {getFieldDecorator('description', {
                                    rules: [{required: true, message: "Please describe your service"}]
                                })(<Input.TextArea rows={4}/>)}
                            </Form.Item>
                            <Form.Item label="What is the URL to the service's homepage?">
                                {getFieldDecorator('url', {
                                    pattern: "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-zA-Z0-9]+([\\-\\.]{1}[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\\/.*)?$",
                                    message: 'The input is not a valid url',
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="What is the contact email address for this service?">
                                {getFieldDecorator('email', {
                                    type: 'email',
                                    message: 'The input is not a valid email address',
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Who are the typical users of this service?">
                                {getFieldDecorator('users', {
                                    rules: [{required: true, message: "Please describe your users"}]
                                })(<Input.TextArea rows={4}/>)}
                            </Form.Item>

                            <Form.Item label="What is the financial model behind this service?">
                                {getFieldDecorator('financialmodel', {
                                    rules: [{required: true, message: "Please select your financial model"}]
                                })(
                                    <RadioGroup>
                                        {Object.entries(surveyCatalog.financialmodel).map(([k, v]) => (
                                            <Radio className="vertical-radio" key={k} value={k}>{v}</Radio>
                                        ))}
                                    </RadioGroup>)}
                            </Form.Item>

                            {financialmodel === "other" ? (
                                <Form.Item label="Please specify your financial model:">
                                    {getFieldDecorator('financialmodelOther', {
                                        rules: [{required: true, message: "Please state your financial model"}]
                                    })(<Input/>)}
                                </Form.Item>) : null}

                            {financialmodel === "free" ? (

                                <Form.Item>
                                    <p>If the service is free, is there a limit on the duration it is offered?</p>
                                    {getFieldDecorator('duration', {
                                        rules: [{required: true, message: "This field is required"}]
                                    })(<Input/>)}
                                </Form.Item>) : null
                            }

                            <Form.Item>
                                <p>Is this service limited to a geographical or political region, or to users coming
                                    from a specific geographical or political region?</p>
                                {getFieldDecorator('location', {
                                    rules: [{required: true, message: "Please select your service location"}]
                                })(
                                    <RadioGroup>
                                        {Object.entries(surveyCatalog.location).map(([k, v]) => (
                                            <Radio className="vertical-radio" key={k} value={k}>{v}</Radio>
                                        ))}
                                    </RadioGroup>)}
                            </Form.Item>

                            {location === "city" || location === "province" || location === "other" ? (
                                <Form.Item label="Please specify where your service is active:">
                                    {getFieldDecorator('locationSpecific', {
                                        rules: [{required: true, message: "Please state your service location"}]
                                    })(<Input/>)}
                                </Form.Item>) : null}


                            <Form.Item>
                                <p>Was this service developed via an existing H2020, EIT or other EU project? If yes,
                                    please
                                    specify and provide a URL?</p>
                                {getFieldDecorator('is_euproject', {
                                    rules: [{required: true, message: "This field is required"}]
                                })(
                                    <RadioGroup>
                                        <Radio className="vertical-radio" value="completely">Completely</Radio>
                                        <Radio className="vertical-radio" value="partially">Partially</Radio>
                                        <Radio className="vertical-radio" value="no">No</Radio>
                                    </RadioGroup>)}
                            </Form.Item>

                            {isEuproject === "completely" || isEuproject === "partially" ? (
                                <Form.Item label="Please specify the EU Project and provide an URL:">
                                    {getFieldDecorator('euproject', {
                                        rules: [{required: true, message: "Please state your financial model"}]
                                    })(<TextArea rows={2}/>)}
                                </Form.Item>) : null}


                            <Form.Item>
                                <p>How long has this service been operational?</p>
                                {getFieldDecorator('age', {
                                    rules: [{required: true, message: "This field is required"}]
                                })(
                                    <RadioGroup>
                                        {Object.entries(surveyCatalog.operational).map(([k, v]) => (
                                            <Radio className="vertical-radio" key={k} value={k}>{v}</Radio>
                                        ))}
                                    </RadioGroup>
                                )}
                            </Form.Item>

                            <Button type="primary" htmlType="submit" disabled={submitting}>Next</Button>

                        </div>) : <Text disabled>Please select a service to continue</Text>}


                    {error ? (<Alert style={{marginTop:24}} message={error} description={errorDescription} showIcon type="error"/>) : null}
                </Form>
            </div>
        );
    }
}

export default Form.create({name: 'service'})(RegisterService);