import React, {Component} from 'react';
import {Button, Checkbox, Col, Icon, Input, Row, Table} from "antd";
import {inject, observer} from "mobx-react";

class ExpandedEntryRow extends Component {
    render() {
        const {entry} = this.props;
        return (
            <div>
                <h3>{entry.name}</h3>
                <p><Icon type="tags"/> <em>Topic:</em> {entry.topic}</p>
                <p><Icon type="tag"/> <em>Type:</em> {entry.type}</p>
                <div><Icon type="global"/> <em>DIH-HERO partner countries:</em>
                    <ul style={{marginTop: 0, paddingTop: 0, display: 'inline-block', verticalAlign: 'top'}}>
                        {entry.countries.map(country => (<li key={country}>{country}</li>))}
                    </ul>
                </div>

            </div>)
    }
}


@inject("surveyStore")
@observer
class DBList extends Component {

    constructor() {
        super();

        this.state = {topicFilter: '', filters: {}};

        this.onServiceTypeCheck = this.onServiceTypeCheck.bind(this);
    }

    componentDidMount() {
        const {surveyStore} = this.props;
        surveyStore.loadDb();

    }

    onServiceTypeCheck(selectedOfferIds) {
        this.setState({selectedOfferIds});
    };


    render() {
        const {textFilter, filters} = this.state;
        const {surveyStore} = this.props;

        const db = surveyStore.db;
        const {dbTopics, dbTypes, dbCountries} = surveyStore;

        function filterEntry(entry) {
            if (filters.topic && filters.topic.length && !filters.topic.includes(entry.topic)) {
                return false;
            }
            if (filters.type && filters.type.length && !filters.type.includes(entry.type)) {
                return false;
            }

            if (filters.countries && filters.countries.length) {
                if (!entry.countries.find(country => filters.countries.includes(country)))
                    return false;
            }

            if (textFilter && textFilter.length) {

                const lowercaseFilter = textFilter.toLowerCase();

                if (!entry.topic.toLowerCase().includes(lowercaseFilter)
                    && !entry.type.toLowerCase().includes(lowercaseFilter)
                    && !entry.name.toLowerCase().includes(lowercaseFilter)
                ) {
                    return false;
                }


            }

            return true;
        }

        const filteredDb = db.filter(filterEntry);

        // const compareByAlph = function (a, b) {
        //     if (a > b) {
        //         return -1;
        //     }
        //     if (a < b) {
        //         return 1;
        //     }
        //     return 0;
        // };
        //
        // const shortenText = (text) => {
        //     if (text.length < MAX_DESCRIPTION_LENGTH) {
        //         return text
        //     } else {
        //         let shortText = text.slice(0, MAX_DESCRIPTION_LENGTH);
        //         shortText = shortText.slice(0, shortText.lastIndexOf(' ')) + " ...";
        //         return shortText;
        //     }
        // };


        const columns = [
            {
                title: 'Topic',
                dataIndex: 'topic',
                key: 'topic',
                width: 150,
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 150,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ];


        const updateFilters = (filterKey) => (checkedValues) => {
            const newFilters = {
                ...this.state.filters,
                [filterKey]: checkedValues
            };

            this.setState({
                filters: newFilters
            });
        };

        const resetFilters = () => {
            this.setState({textFilter: '', filters: {}});
        };


        // const updateSelectedOrganisations = (selectedOrgs) => {
        //     this.setState({selectedOrgs})
        // };

        return (
            <Row gutter={16}>
                <Col xs={{span: 24, order: 2}} md={{span: 6}}>

                    <div style={{background: '#bae7ff', padding: 24, marginBottom: 24, minHeight: 100}}>
                        <h3>Search</h3>

                        <Input.Search onSearch={value => this.setState({textFilter: value})}/>
                    </div>


                    <Button onClick={resetFilters} style={{width: '100%', marginBottom: 24}}><Icon
                        type="undo"/> Reset Filters</Button>


                    <div style={{background: '#b7eb8f', padding: 24, marginBottom: 24, minHeight: 280}}>
                        <h3>Filters</h3>

                        <h4 style={{marginTop: 24}}>Topic</h4>

                        <div style={{padding: 16, backgroundColor: '#fff'}}>
                            <Checkbox.Group onChange={updateFilters('topic')}
                                            value={(this.state.filters.topic || [])}>
                                {dbTopics.map((topic) => (
                                    <div key={topic}><Checkbox value={topic}>{topic}
                                        <small
                                            style={{paddingLeft: 4}}>({db.filter(entry => entry.topic === topic).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>

                        <h4 style={{marginTop: 24}}>Type</h4>
                        <div style={{padding: 16, backgroundColor: '#fff'}}>
                            <Checkbox.Group onChange={updateFilters('type')}
                                            value={(this.state.filters.type || [])}>
                                {dbTypes.map((type) => (
                                    <div key={type}><Checkbox value={type}>{type}
                                        <small
                                            style={{paddingLeft: 4}}>({db.filter(entry => entry.type === type).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>

                        <h4 style={{marginTop: 24}}>Applied/promoted by DIH-HERO partner countries in</h4>
                        <div style={{padding: 16, backgroundColor: '#fff'}}>
                            <Checkbox.Group onChange={updateFilters('countries')}
                                            value={(this.state.filters.countries || [])}>
                                {dbCountries.map((country) => (
                                    <div key={country}><Checkbox value={country}>{country}
                                        <small
                                            style={{paddingLeft: 4}}>({db.filter(entry => entry.countries.includes(country)).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>


                    </div>


                </Col>
                <Col xs={{span: 24, order: 1}} md={{span: 18}}>
                    <div style={{background: '#fff', padding: 24, minHeight: 280}}>
                        <h1>Standards relevant to healthcare robotics and applied/promoted by DIH-HERO partners</h1>

                        <h4>{filteredDb.length} results</h4>

                        <Table dataSource={filteredDb} columns={columns}
                               rowKey={(record) => record.type + record.topic + record.name}
                               size="middle"
                               pagination={{position: 'both', defaultPageSize: 20, showSizeChanger: true}}
                               onChange={(pagination, filters, sorter) => {
                                   this.setState({filters: filters});
                               }}
                               expandedRowRender={record => (<ExpandedEntryRow entry={record}/>)}

                        />
                    </div>
                </Col>

            </Row>


        );
    }
}

export default DBList;