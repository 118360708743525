import React, {Component} from 'react';

class Done extends Component {
    render() {
        return (
            <div>
                <h1>Contact Us</h1>

                <p>If you have any questions concerning this survey, please send an e-mail to <span className="mail"
                                                                                                    data-addr="dih-hero"
                                                                                                    data-dom="ilabt.imec.be"/></p>
            </div>
        );
    }
}

export default Done;