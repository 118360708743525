import React, {Component} from 'react';
import {Button, Checkbox, Col, Icon, Input, Row, Select, Table, Tooltip, Tree} from "antd";
import {inject, observer} from "mobx-react";
import surveyCatalog from './survey.json';
import serviceCatalog from './service-catalog.json';

const MAX_DESCRIPTION_LENGTH = 100;

class ExpandedServiceRow extends Component {
    render() {
        const {service} = this.props;


        const mainArea = serviceCatalog[service.offer_category];
        const offer = mainArea ? mainArea.services[service.offer_id] : null;

        let financialModel;
        if (service.financialmodel.startsWith("other[")) {
            financialModel = service.financialmodel;
            financialModel = financialModel.substr(6, financialModel.length - 6 - 1/*extra for omitting last ]*/);
            financialModel = "Other (" + financialModel + ")";
        } else {
            financialModel = surveyCatalog.financialmodel[service.financialmodel];
        }

        let location;

        if (service.location.startsWith("city[")) {
            location = service.location;
            location = location.substr(5, location.length - 5 - 1/*extra for omitting last ]*/);
            location = "City or District (" + location + ")";

        }
        if (service.location.startsWith("province[")) {
            location = service.location;
            location = location.substr(9, location.length - 9 - 1/*extra for omitting last ]*/);
            location = "Province or State (" + location + ")";

        } else if (service.location.startsWith("other[")) {
            location = service.location;
            location = location.substr(6, location.length - 6 - 1/*extra for omitting last ]*/);
            location = "Other (" + location + ")";
        } else {
            location = surveyCatalog.location[service.location] || service.location;
        }

        let euproject = (<span><Icon type="close" style={{color: '#8c8c8c'}}/> No</span>);

        if (service.is_euproject === "completely") {
            euproject = (<span><Icon type="check" style={{color: '#8c8c8c'}}/> Completely: <a
                href={service.euproject}>{service.euproject}</a></span>)
        } else if (service.is_euproject === "partially") {
            euproject = (<span><Icon type="plus" style={{color: '#8c8c8c'}}/> Partially:<a
                href={service.euproject}>{service.euproject}</a></span>)
        }

        const orgType = service.org_type.startsWith('other') ? service.org_type.slice(6, -1) : surveyCatalog.organisationTypes[service.org_type];

        const fixUrl = (url) => {
            if (url.startsWith('http')) {
                return url;
            } else {
                return "http://" + url;
            }
        };

        return (
            <Row gutter={16}>
                <Col xs={{span: 24}} md={{span: 12}}>
                    <h3>Service Description</h3>
                    <p>{service.description}</p>


                    {service.url ? <p><Icon type="link"/> <a href={fixUrl(service.url)}>{service.url}</a></p> : null}
                    {service.email ?
                        <p><Icon type="mail"/> <a href={`mailto:${service.email}`}>{service.email}</a></p> : null}

                    <p><Icon type="tag"/><em> Service Category: </em> {mainArea.name} <Tooltip
                        title={mainArea.description}
                        placement="bottom"><Icon
                        type="question-circle"/></Tooltip></p>

                    <p><Icon type="tags"/><em> Service Area: </em>{offer.name} <Tooltip title={offer.description}
                                                                                        placement="bottom"><Icon
                        type="question-circle"/></Tooltip></p>

                    <p><Icon type="euro"/><em> Financial model: </em> {financialModel}
                        {service.duration ? (<span> (Duration: {service.duration})</span>) : []}
                    </p>

                    <p><Icon type="history"/><em> Service has been operational
                        for: </em>{surveyCatalog.operational[service.age]} </p>

                    <p><Icon type="global"/><em> Available: </em> {location}</p>

                    <p><Icon type="star"/><em> Service was developed by EU project: </em> {euproject}</p>

                    <h3>Users Description</h3>
                    <p>{service.users}</p>
                </Col>
                <Col xs={{span: 24}} md={{span: 12}} style={{backgroundColor: '#fff', padding: 16}}>
                    <h3>Organisation</h3>
                    <p>{service.org_name} <a href={service.org_url}><Icon type="link"/></a></p>
                    {service.org_url ?
                        <p><Icon type="link"/> <a href={fixUrl(service.org_url)}>{service.org_url}</a></p> : null}
                    {service.org_contact_email ?
                        <p><Icon type="mail"/> <a
                            href={`mailto:${service.org_contact_email}`}>{service.org_contact_name} ({service.org_contact_email})</a>
                        </p> : null}


                    <p><Icon type="global"/><em> Location: </em> {surveyCatalog.countries[service.org_country]}</p>
                    <p><Icon type="shop"/><em> Type: </em> {orgType}</p>

                </Col>
            </Row>)
    }
}


@inject("surveyStore")
@observer
class ServicesList extends Component {

    constructor() {
        super();

        this.state = {textFilter: '', selectedOfferIds: [], filters: {}, selectedOrgs: []};

        this.onServiceTypeCheck = this.onServiceTypeCheck.bind(this);
    }

    componentDidMount() {
        const {surveyStore} = this.props;
        surveyStore.loadAllServices();

        const preselectedTypeId = this.props.match.params.type;
        if(preselectedTypeId !== null && serviceCatalog[preselectedTypeId]){

            const selectedOfferIds = [preselectedTypeId, ...Object.keys(serviceCatalog[preselectedTypeId].services)];
            this.setState({selectedOfferIds});
        }

    }



    onServiceTypeCheck(selectedOfferIds) {
        this.setState({selectedOfferIds});

        function eqSet(as, bs) {
            if (as.size !== bs.size) return false;
            for (var a of as) if (!bs.has(a)) return false;
            return true;
        }
        let suffix = "";

        const selectedIds = new Set(selectedOfferIds);

        for(const service of Object.keys(serviceCatalog)){
            const serviceSet = new Set([service, ...Object.keys(serviceCatalog[service].services)]);
            console.log("comparing", selectedIds, serviceSet);
            if(eqSet(selectedIds, serviceSet)){
                suffix = `/${service}`;
                break;
            }
        }

        window.history.replaceState(null,"",`/directory${suffix}`); //remove any pre-checked category from the url
    };


    render() {
        const {selectedOfferIds, textFilter, filters, selectedOrgs} = this.state;
        const {surveyStore} = this.props;

        let services = surveyStore.servicesDataSource;
        const organisations = surveyStore.organisationsList;

        function filterService(service) {
            if (selectedOfferIds.length && !selectedOfferIds.includes(service.offer_id)) {
                return false;
            }

            if (textFilter.length) {

                const lowercaseFilter = textFilter.toLowerCase();

                if (!service.description.toLowerCase().includes(lowercaseFilter)
                    && !service.org_name.toLowerCase().includes(lowercaseFilter)
                    && !service.users.toLowerCase().includes(lowercaseFilter)
                    && !service.financialmodel.toLowerCase().includes(lowercaseFilter)
                    //  && !service.euproject.toLowerCase().includes(lowercaseFilter)
                    //   && (service.url !== null && !service.url.toLowerCase().includes(lowercaseFilter))
                    //  && !service.org_url.toLowerCase().includes(lowercaseFilter)
                    && !service.org_type.toLowerCase().includes(lowercaseFilter)
                ) {
                    return false;
                }


            }

            if ((filters.age || []).length) {
                if (!filters.age.includes(service.age))
                    return false;
            }
            if ((filters.location || []).length) {
                if (!filters.location.includes(service.location)
                    && !(service.location.startsWith('other') && filters.location.includes('other')))
                    return false;
            }
            if (selectedOrgs.length) {
                if (!selectedOrgs.includes(service.org_name))
                    return false;
            }

            if ((filters.org_type || []).length) {
                if (!filters.org_type.includes(service.org_type))
                    return false;
            }

            if ((filters.org_country || []).length) {
                if (!filters.org_country.includes(service.org_country))
                    return false;
            }

            if ((filters.financialmodel || []).length) {
                if (!filters.financialmodel.includes(service.financialmodel)
                    && !(service.financialmodel.startsWith('other') && filters.financialmodel.includes('other')))
                    return false;
            }


            return true;
        }

        services = services.filter(filterService);

        const compareByAlph = function (a, b) {
            if (a > b) {
                return -1;
            }
            if (a < b) {
                return 1;
            }
            return 0;
        };

        const shortenText = (text) => {
            if (text.length < MAX_DESCRIPTION_LENGTH) {
                return text
            } else {
                let shortText = text.slice(0, MAX_DESCRIPTION_LENGTH);
                shortText = shortText.slice(0, shortText.lastIndexOf(' ')) + " ...";
                return shortText;
            }
        };


        const columns = [
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                render: shortenText,
            },
            {
                title: 'Service Type',
                dataIndex: 'offer_id',
                key: 'offer_id',
                render: (offerId) => {
                    const serviceCategory = offerId.slice(0, offerId.indexOf('_'));
                    return serviceCatalog[serviceCategory].services[offerId].name;
                },
            },
            {
                title: 'Organisation',
                dataIndex: 'org_name',
                key: 'org_name',
                render: (orgName, service) => {
                    return <span>{orgName} <a href={service.org_url}><Icon type="link"/></a></span>
                },
                sorter: (a, b) => compareByAlph(a.org_name, b.org_name),
            },
            {
                title: 'Organisation Type',
                dataIndex: 'org_type',
                key: 'org_type',
                filteredValue: this.state.filters.org_type || null,
                filters: Object.entries(surveyCatalog.organisationTypes).map(([k, v]) => {
                    return {text: v, value: k}
                }),
                onFilter: (value, record) => true, //record.org_type === value,
                render: (orgType) => {
                    if (!orgType.startsWith('other')) {
                        return surveyCatalog.organisationTypes[orgType];
                    } else {
                        return orgType.slice(6, -1);
                    }
                }
            },

            {
                title: 'Country',
                dataIndex: 'org_country',
                key: 'org_country',
                filteredValue: this.state.filters.org_country || null,
                filters: Object.entries(surveyCatalog.countries).map(([k, v]) => {
                    return {text: v, value: k}
                }),
                onFilter: (value, record) => true, //record.org_country === value,
                render: (orgCountry) => {
                    return surveyCatalog.countries[orgCountry];

                },
                sorter: (a, b) => compareByAlph(a.org_country, b.org_country),
            },

            {
                title: 'Financial Model',
                dataIndex: 'financialmodel',
                key: 'financialmodel',
                filteredValue: this.state.filters.financialmodel || null,
                filters: Object.entries(surveyCatalog.financialmodel).map(([k, v]) => {
                    return {text: v, value: k}
                }),
                onFilter: (value, record) => true,//record.financialmodel.startsWith(value),
                render: (financialmodel) => {
                    if (!financialmodel.startsWith('other')) {

                        return surveyCatalog.financialmodel[financialmodel];
                    } else {
                        return financialmodel.slice(6, -1);
                    }

                }
            },
        ];


        const updateFilters = (filterKey) => (checkedValues) => {
            const newFilters = {
                ...this.state.filters,
                [filterKey]: checkedValues
            };

            this.setState({
                filters: newFilters
            });
        };

        const resetFilters = () => {
            this.setState({textFilter: '', selectedOfferIds: [], filters: {}, selectedOrgs: []});
        };


        const updateSelectedOrganisations = (selectedOrgs) => {
            this.setState({selectedOrgs})
        };

        return (
            <Row gutter={16}>
                <Col xs={{span: 24, order: 2}} md={{span: 6}}>

                    <div style={{background: '#bae7ff', padding: 24, marginBottom: 24, minHeight: 100}}>
                        <h3>Search</h3>

                        <Input.Search onSearch={value => this.setState({textFilter: value})}/>
                    </div>


                    <Button onClick={resetFilters} style={{width: '100%', marginBottom: 24}}><Icon
                        type="undo"/> Reset Filters</Button>


                    <div style={{background: '#b7eb8f', padding: 24, marginBottom: 24, minHeight: 280}}>
                        <h3>Service Filters</h3>

                        <div style={{overflowX: 'scroll'}}>
                            <h4 style={{marginTop: 24}}>Service Type</h4>

                            <Tree checkable autoExpandParent onCheck={this.onServiceTypeCheck}
                                  checkedKeys={selectedOfferIds}
                                  style={{backgroundColor: '#fff'}}>
                                {Object.keys(serviceCatalog).map(catKey => (
                                    <Tree.TreeNode title={<span>{serviceCatalog[catKey].name}
                                        <small
                                            style={{paddingLeft: 4}}>({services.filter(service => service.offer_category === catKey).length})</small></span>}
                                                   key={catKey}>
                                        {Object.keys(serviceCatalog[catKey].services).map(key => (
                                            <Tree.TreeNode title={<span>{serviceCatalog[catKey].services[key].name}
                                                <small
                                                    style={{paddingLeft: 4}}>({services.filter(service => service.offer_id === key).length})</small></span>}
                                                           key={key}/>
                                        ))}
                                    </Tree.TreeNode>
                                ))}
                            </Tree>
                        </div>

                        <h4 style={{marginTop: 24}}>Financial Model</h4>
                        <div style={{padding: 16, backgroundColor: '#fff'}}>
                            <Checkbox.Group onChange={updateFilters('financialmodel')}
                                            value={(this.state.filters.financialmodel || [])}>
                                {Object.entries(surveyCatalog.financialmodel).map(([k, v]) => (
                                    <div key={k}><Checkbox value={k}>{v}
                                        <small
                                            style={{paddingLeft: 4}}>({services.filter(service => service.financialmodel.startsWith(k)).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>

                        <h4 style={{marginTop: 24}}>Operational for</h4>
                        <div style={{padding: 16, backgroundColor: '#fff'}}>
                            <Checkbox.Group onChange={updateFilters('age')} value={(this.state.filters.age || [])}>
                                {Object.entries(surveyCatalog.operational).map(([k, v]) => (
                                    <div key={k}><Checkbox value={k}>{v}
                                        <small
                                            style={{paddingLeft: 4}}>({services.filter(service => service.age === k).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>

                        <h4 style={{marginTop: 24}}>Locality</h4>
                        <div style={{padding: 16, backgroundColor: '#fff'}}>
                            <Checkbox.Group onChange={updateFilters('location')}
                                            value={(this.state.filters.location || [])}>
                                {Object.entries(surveyCatalog.location).map(([k, v]) => (
                                    <div key={k}><Checkbox value={k}>{v}
                                        <small
                                            style={{paddingLeft: 4}}>({services.filter(service => service.location.startsWith(k)).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>

                    </div>

                    <div style={{background: '#87e8de', padding: 24, marginBottom: 24, minHeight: 280}}>
                        <h3>Organisation Filters</h3>

                        <h4 style={{marginTop: 24}}>Name</h4>
                        <Select mode="tags" onChange={updateSelectedOrganisations} style={{width: '100%'}}>
                            {organisations.map(org => <Select.Option key={org}>{org}</Select.Option>)}

                        </Select>

                        <h4 style={{marginTop: 24}}>Country</h4>
                        <div style={{padding: 16, backgroundColor: '#fff', maxHeight: 240, overflowY: 'scroll'}}>
                            <Checkbox.Group onChange={updateFilters('org_country')}
                                            value={(this.state.filters.org_country || [])}>
                                {Object.entries(surveyCatalog.countries).map(([k, v]) => (
                                    <div key={k}><Checkbox value={k}>{v}
                                        <small
                                            style={{paddingLeft: 4}}>({services.filter(service => service.org_country === k).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>

                        <h4 style={{marginTop: 24}}>Organisation Type</h4>
                        <div style={{padding: 16, backgroundColor: '#fff', maxHeight: 240, overflowY: 'scroll'}}>
                            <Checkbox.Group onChange={updateFilters('org_type')}
                                            value={(this.state.filters.org_type || [])}>
                                {Object.entries(surveyCatalog.organisationTypes).map(([k, v]) => (
                                    <div key={k}><Checkbox value={k}>{v}
                                        <small
                                            style={{paddingLeft: 4}}>({services.filter(service => service.org_type === k).length})
                                        </small>
                                    </Checkbox>
                                    </div>))}
                            </Checkbox.Group>
                        </div>
                    </div>

                    <Button onClick={resetFilters} style={{width: '100%'}}><Icon type="undo"/> Reset Filters</Button>


                </Col>
                <Col xs={{span: 24, order: 1}} md={{span: 18}}>
                    <div style={{background: '#fff', padding: 24, minHeight: 280}}>
                        <h1>Services Directory</h1>

                        <h4>{services.length} results</h4>

                        <Table dataSource={services} columns={columns}
                               rowKey={(record) => record.description}
                               size="middle"
                               pagination={{position: 'both', defaultPageSize: 20, showSizeChanger: true}}
                               onChange={(pagination, filters, sorter) => {
                                   this.setState({filters: filters});
                               }}
                               expandedRowRender={record => (<ExpandedServiceRow service={record}/>)}

                        />
                    </div>
                </Col>

            </Row>


        );
    }
}

export default ServicesList;