import React, {Component} from 'react';
import './form.css';

import {Form, Select, Button, Input, Radio, Alert, notification} from 'antd';
import {registerOrganisation} from './api.js';
import surveyCatalog from './survey.json';

const {Option} = Select;
const RadioGroup = Radio.Group;


class RegisterOrganisation extends Component {

    constructor() {
        super();
        this.state = {error: null, errorDescription: null}
    }


    handleSubmit = (e) => {
        e.preventDefault();
        console.log('here');
        this.props.form.validateFields((error, value) => {

            console.log('formsubmit', error, value);

            if (error === null) {

                const org = {
                    ...value
                };

                if (org.typeOther) {
                    org.type = "other[" + org.typeOther + "]";
                    org.typeOther = undefined;
                }


                registerOrganisation(org).then(registeredOrg => {
                    this.props.history.push(`/organisation/${registeredOrg.id}`);
                    this.props.history.push(`/organisation/${registeredOrg.id}/service/register`);

                    notification.info({
                        message: 'Running out of time?',
                        description: "We've sent you an email with an unique link to this survey. You can always use this link to come back later and complete this survey.",
                        duration: 6

                    })

                }).catch((err) => {
                    this.setState({
                        error: "Could not register your answers",
                        errorDescription: err.toString()
                    })
                });

            }
        });
    };

    render() {
        const {error, errorDescription} = this.state;
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <div>
                <h1>Organisation Information</h1>

                <Form style={{marginTop: 32}} onSubmit={this.handleSubmit}>
                    <Form.Item
                        label="Organisation Name"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true, message: 'This field is required',
                            }],
                        })(
                            <Input/>)}
                    </Form.Item>
                    <Form.Item
                        label="Organisation URL"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('url', {
                            rules: [{
                                pattern: "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-zA-Z0-9]+([\\-\\.]{1}[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\\/.*)?$",
                                message: 'The input is not a valid url',
                            }, {
                                required: true, message: 'This field is required',
                            }],
                        })(
                            <Input/>)}
                    </Form.Item>
                    <Form.Item
                        label="Organisation Type"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('type', {
                            rules: [{
                                required: true, message: 'This field is required',
                            }],
                        })(
                            <RadioGroup>
                                {Object.entries(surveyCatalog.organisationTypes).map(([k, v]) => (
                                    <Radio className="vertical-radio" key={k} value={k}>{v}</Radio>
                                ))}
                                <Radio className="vertical-radio"
                                       value="other">Other
                                </Radio>
                            </RadioGroup>)}

                    </Form.Item>

                    {this.props.form.getFieldValue('type') === "other" ?
                        (<Form.Item label="Other Organisation Type" {...formItemLayout}>

                            {getFieldDecorator('typeOther', {
                                rules: [{
                                    required: true, message: 'This field is required',
                                }],
                            })(<Input style={{width: 200}}/>)}</Form.Item>) : null}

                    <Form.Item
                        label="Country"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('country', {
                            rules: [{
                                required: true, message: 'This field is required',
                            }],
                        })(
                            <Select>
                                {Object.entries(surveyCatalog.countries).map(([k, v]) => (
                                    <Option key={k} value={k}>{v}</Option>
                                ))}
                            </Select>)}
                    </Form.Item>

                    <Form.Item
                        label="Region"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('region', {
                            rules: [{
                                required: true, message: 'This field is required',
                            }],
                        })(
                            <Input/>)}
                    </Form.Item>

                    <Form.Item
                        label="Contact Name"
                        {...formItemLayout}
                        help="Please specify a contact person that we may get in touch with for follow-up questions about this survey."
                    >
                        {getFieldDecorator('contact_name', {
                            rules: [{
                                required: true, message: 'Please input your contact name',
                            }],
                        })(
                            <Input/>)}
                    </Form.Item>

                    <Form.Item
                        label="Contact Email"
                        {...formItemLayout}
                        help="This email address will only be used for queries related to this survey."
                    >
                        {getFieldDecorator('contact_email', {
                            rules: [{
                                type: 'email', message: 'The input is not a valid e-mail',
                            }, {
                                required: true, message: 'Please input your contact e-mail',
                            }],
                        })(
                            <Input/>)}
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" size="large">Next</Button>


                        {error ? (<Alert style={{marginTop: 24}} message={error} description={errorDescription} showIcon
                                         type="error"/>) : null}
                    </Form.Item>
                </Form>

            </div>
        );
    }
}

export default Form.create({name: 'organisation'})(RegisterOrganisation);