import React from 'react';
import ReactDOM from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import {BrowserRouter as Router} from "react-router-dom";

import surveyStore from './store/survey';
import ScrollToTop from "./ScrollToTop";

const stores = {
    surveyStore
};

// For easier debugging
window._____APP_STATE_____ = stores;

promiseFinally.shim();
configure({enforceActions: "always"});

ReactDOM.render(<Router>
    <ScrollToTop><Provider {...stores}><App/></Provider></ScrollToTop></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
