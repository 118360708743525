import React, {Component} from 'react';
import './App.css';
import logo from './images/logo-dih-hero.svg';
import euflag from './images/eu-flag.jpg';

import {Layout, Menu, Breadcrumb, Icon} from 'antd';
import {Route, Switch, NavLink} from "react-router-dom";
import {Homepage} from './Homepage'
import RegisterOrganisation from "./RegisterOrganisation";
import RegisterService from "./RegisterService";
import Organisation from "./Organisation";
import Done from "./Done";
import Contact from "./Contact";
import {withRouter} from "react-router";
import ServicesList from "./ServicesList";
import {SurveyStart} from "./SurveyStart";
import DBList from "./DB";

const {Header, Content, Footer} = Layout;


class App extends Component {

    render() {

        const {location} = this.props;

        const breadcrumbItems = [
            <Breadcrumb.Item href="/"><Icon type="home"/></Breadcrumb.Item>
        ];

        const pathParts = location.pathname.split('/').filter(i => i);

        if (pathParts.length === 0)
            breadcrumbItems.push(<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>);
        else if (pathParts[0] === "start") {
            breadcrumbItems.push(<Breadcrumb.Item href="/start">Start Survey</Breadcrumb.Item>);
        } else if (pathParts[0] === "directory") {
            breadcrumbItems.push(<Breadcrumb.Item href="/directory">Services List</Breadcrumb.Item>);
        } else if (pathParts[0] === "db" || pathParts[0] === "standards") {
            breadcrumbItems.push(<Breadcrumb.Item href="/directory">Standards List</Breadcrumb.Item>);
        } else if (pathParts[0] === "organisation") {

            if (pathParts.length === 2) {
                if (pathParts[1] === "register") {
                    breadcrumbItems.push(<Breadcrumb.Item href="/organisation/register">Register a new
                        Organisation</Breadcrumb.Item>);
                } else {
                    breadcrumbItems.push(<Breadcrumb.Item>Organisation</Breadcrumb.Item>);
                    breadcrumbItems.push(<Breadcrumb.Item href={`/organisation/${pathParts[1]}`}>Services
                        Overview</Breadcrumb.Item>);
                }
            }

            if (pathParts.length === 4) {
                breadcrumbItems.push(<Breadcrumb.Item>Organisation</Breadcrumb.Item>);
                breadcrumbItems.push(<Breadcrumb.Item
                    href={`/organisation/${pathParts[1]}`}>Services</Breadcrumb.Item>);
                breadcrumbItems.push(<Breadcrumb.Item>Register a new Service</Breadcrumb.Item>);
            }


        }


        return (<Layout className="layout">
            <Header>
                <div className="logo"><img src={logo} style={{height: 30}} alt="DIH Hero"/></div>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    style={{lineHeight: '64px'}}
                >
                    <Menu.Item key="1"><NavLink to="/">Startpage</NavLink></Menu.Item>
                    <Menu.Item key="2"><NavLink to="/directory">Services Directory</NavLink></Menu.Item>
                    <Menu.Item key="3"><NavLink to="/standards">Standards Directory</NavLink></Menu.Item>
                    <Menu.Item key="4"><a href="https://dih-hero.eu/about-dih-hero/">About
                        DIH-HERO</a></Menu.Item>
                    <Menu.Item key="5"><NavLink to="/contact">Contact</NavLink></Menu.Item>
                </Menu>
            </Header>
            <Content style={{padding: '0 50px'}}>

                <Breadcrumb style={{margin: '16px 0'}}>
                    {breadcrumbItems}
                </Breadcrumb>
                <Switch>
                    <Route path="/db" component={DBList}/>
                    <Route path="/standards" component={DBList}/>
                    <Route path="/directory/:type?" component={ServicesList}/>
                    <Route>
                        <div style={{background: '#fff', padding: 24, minHeight: 280}}>

                            <Switch>
                                <Route path="/" exact component={Homepage}/>
                                <Route path="/start" exact component={SurveyStart}/>
                                <Route path="/organisation/register" exact component={RegisterOrganisation}/>
                                <Route path="/organisation/:id/service/register" exact component={RegisterService}/>
                                <Route path="/organisation/:id" component={Organisation}/>
                                <Route path="/thankyou" component={Done}/>
                                <Route path="/contact" component={Contact}/>
                            </Switch>
                        </div>
                    </Route>
                </Switch>

            </Content>
            <Footer style={{textAlign: 'center'}}>
                <p>DIH-HERO ©2019 Created by <a href="https://www.imec-int.com">imec vzw</a></p>
                <p><img src={euflag} style={{height: 30, marginRight: 16}} alt="EU"/> This project has
                    received
                    funding from the
                    European
                    Union’s Horizon 2020 research and innovation programme under grant agreement No 825003.
                </p>
                <p style={{color: 'rgba(0,0,0,0.65)'}}>More information on the processing and handling of
                    your
                    data including GDPR can be found in the <a
                        href="https://www.imec-int.com/en/privacy-statement">Privacy statement</a></p>
            </Footer>
        </Layout>);
    }
}

export default withRouter(App);
