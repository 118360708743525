import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Alert, Button, Card, Col, Icon, Popconfirm, Row, Skeleton, message} from "antd";
import {inject, observer} from "mobx-react";
import {finishSurveyForOrganisation} from './api.js'

import serviceCatalog from './service-catalog.json';
import surveyCatalog from './survey.json';

const BASE_URL = "https://survey.dih-hero.ilabt.imec.be";

@inject("surveyStore")
@observer
class Organisation extends Component {

    constructor(props) {
        super(props);

        this.state = {admin: false};
    }


    componentWillMount() {
    }

    componentDidMount() {
        const {surveyStore} = this.props;
        const orgId = this.props.match.params.id;
        surveyStore.loadOrganisation(orgId);
        surveyStore.loadServices(orgId);

    }


    render() {
        const {surveyStore} = this.props;
        const organisationId = this.props.match.params.id;

        const org = surveyStore.organisations.get(organisationId);
        const services = surveyStore.servicesPerOrganisation.get(organisationId) || [];

        if (!org) {
            return (<div><Skeleton/></div>)
        }

        const url = this.props.location.pathname;

        const deleteService = (serviceId) => {
            surveyStore.deleteService(organisationId, serviceId)
                .then(success => {
                    if (success) {
                        message.success("The service was deleted");
                    } else {
                        message.error("Could not delete service");
                    }
                });


        };

        const toggleService = (serviceId) => {
            surveyStore.toggleService(organisationId, serviceId);
        };

        const finishSurvey = () => {
            finishSurveyForOrganisation(organisationId);
            this.props.history.push('/thankyou');
        }

        let serviceCounter = 0;

        return (
            <div>
                <h1>Register services for organisation {org.name}</h1>


                <Link to={`/organisation/${organisationId}/service/register`}>
                    <Button type="primary" size="large">Register another service</Button>
                </Link>


                <Button size="large" style={{marginLeft: 24}} onClick={finishSurvey}>Finish the survey</Button>


                <p style={{marginTop: 36}} onDoubleClick={() => {
                    this.setState({admin: true})
                }}>You currently have {services.length} services registered.</p>

                <Row gutter={16} type="flex">
                    {services.map(service => {

                        const mainArea = serviceCatalog[service.offer_category];
                        const offer = mainArea ? mainArea.services[service.offer_id] : null;

                        let financialModel;
                        if (service.financialmodel.startsWith("other[")) {
                            financialModel = service.financialmodel;
                            financialModel = financialModel.substr(6, financialModel.length - 6 - 1/*extra for omitting last ]*/);
                            financialModel = "Other (" + financialModel + ")";
                        } else {
                            financialModel = surveyCatalog.financialmodel[service.financialmodel];
                        }

                        let location;

                        if (service.location.startsWith("city[")) {
                            location = service.location;
                            location = location.substr(5, location.length - 5 - 1/*extra for omitting last ]*/);
                            location = "City or District (" + location + ")";

                        }
                        if (service.location.startsWith("province[")) {
                            location = service.location;
                            location = location.substr(9, location.length - 9 - 1/*extra for omitting last ]*/);
                            location = "Province or State (" + location + ")";

                        } else if (service.location.startsWith("other[")) {
                            location = service.location;
                            location = location.substr(6, location.length - 6 - 1/*extra for omitting last ]*/);
                            location = "Other (" + location + ")";
                        } else {
                            location = surveyCatalog.location[service.location] || service.location;
                        }

                        let euproject = "No";

                        if (service.is_euproject === "completely") {
                            euproject = "Completely: " + service.euproject
                        } else if (service.is_euproject === "partially") {
                            euproject = "Partially: " + service.euproject
                        }


                        const deleteAction = (
                            <Popconfirm title="Are you sure you want to delete this service from your survey answers?"
                                        onConfirm={() => {
                                            deleteService(service.id)
                                        }}
                                        okText="Yes"
                                        cancelText="No">

                                <Icon type="delete"/>
                            </Popconfirm>);

                        const actions = [deleteAction];

                        if (this.state.admin) {
                            actions.push(<span onClick={() => {
                                toggleService(service.id)
                            }}><Icon type={service.visible ?  "eye-invisible":"eye"}/> Make {service.visible ? "invisible": "visible"}</span>)
                        }

                        return (
                            <Col key={service.id}
                                 lg={{span: 12}} style={{marginBottom: 24}}
                                // md={{span: 12}} xl={{span: 8}}
                            ><Card
                                title={<span>{`Service ${++serviceCounter}`} {this.state.admin ?
                                    <Icon type={service.visible ? "eye" : "eye-invisible"}/> : []}</span>}
                                actions={actions}>
                                <p><strong>Service Category:</strong> {mainArea ? mainArea.description : (
                                    <pre>{service.offer_category}</pre>)}
                                </p>
                                <p><strong>Service Area:</strong> {offer ? offer.description : (
                                    <pre>{service.offer_id}</pre>)}
                                </p>
                                <p><strong>Service description:</strong> {service.description}</p>
                                <p><strong>URL: </strong> {service.url || "-"} </p>
                                <p><strong>Contact Email: </strong> {service.email || "-"} </p>
                                <p><strong>Users description:</strong> {service.users}</p>
                                <p><strong>Financial
                                    model:</strong> {financialModel}
                                    {service.duration ? (<span> (Duration: {service.duration})</span>) : []}
                                </p>
                                <p><strong>Location:</strong> {location}</p>
                                <p><strong>EU Project:</strong> {euproject}</p>
                                <p><strong>Age: </strong> {surveyCatalog.operational[service.age]} </p>

                            </Card></Col>)
                    })}
                </Row>

                <div style={{marginTop: 36}}>

                    <Link to={`/organisation/${organisationId}/service/register`}>
                        <Button type="primary" size="large">Register another service</Button>
                    </Link>

                    <Button size="large" style={{marginLeft: 24}} onClick={finishSurvey}>Finish the survey</Button>
                </div>

                <Alert style={{marginTop: 48, maxWidth: 800}} type="info" message={(
                    <span><strong>Tip:</strong> You can always come back to this URL (<Link
                        to={url}>{`${BASE_URL}${url}`}</Link>) to register more services for your organisation.</span>)}/>

            </div>
        );
    }
}

export default Organisation;