import React, {Component} from 'react';

class Done extends Component {
    render() {
        return (
            <div>
                <h1>Thank you!</h1>

                <p>Dear EU Innovator,</p>
                <p>We thank you for your time and effort to help us with this survey. As soon as sufficient responses
                    are received, we will work out the best strategy to expose your services to the healthcare
                    community.</p>
                <p>We hope this will lead to tangible benefits for your organization and to others!</p>
                <p>Do follow the project’s progress at <a href="https://www.dih-hero.eu">www.dih-hero.eu</a></p>
                <p>Best Regards,</p>
                <p>The DIH-HERO Consortium.</p>
            </div>
        );
    }
}

export default Done;