import React from 'react';
import {NavLink} from "react-router-dom";
import {Button} from "antd";

function SurveyStart() {

    return (
        <div>
            <p>Dear EU Innovator,<br/>
                <br/>
                <a href="https://www.dih-hero.eu">DIH-HERO</a> is a pan-European platform aimed at accelerating the EU
                healthcare robotics sector. DIH-HERO is focused on helping small and medium-sized enterprises (SMEs), in
                particular; deliver impactful healthcare robotics products and solutions across the EU and the world.
            </p>
            <p>DIH-HERO offers companies involved in all topics related to healthcare robotics, funding opportunities
                to demonstrate and trial their solutions as well as access to technical and business services to help
                speed up the go-to-market process.</p>
            <p>A broad EU-wide partner network will offer innovation connections, initiated by seventeen leading
                research organisations and universities who are core members of this project.</p>
            <p>This survey aims at identifying the different services offered by companies in the DIH-HERO network that
                could add value in the creation of novel healthcare robotics solutions. Your organisation may already be
                known for specialized services such as, technology development, manufacturing, product certification, or
                even legal aspects of robotics. Through this survey, you can help us advertise your services to the DIH
                community!</p>
            <p>As the contact point of your organisation, we ask for your name and email in case we need to get in touch
                with you for questions and clarifications. If the services you describe are executed by another
                department or branch office, we will ask for their contact information as well. Names and emails will
                not be shared with third parties without your explicit consent.</p>
            <p>By providing us this information, you agree that: </p>
            <ul>
                <li>The DIH-HERO project can publish the data you have provided on its website, brochures or other
                    public
                    media, in meetings (not limited to conferences, expositions and workshops).
                </li>
                <li>
                    The DIH-HERO project can use this information, in raw or processed form, to create connections
                    between
                    suppliers and consumers of services for the purpose of the project.
                </li>
                <li>
                    The data you provide does not include any proprietary or confidential information or belonging
                    to a 3rd
                    party.
                </li>
            </ul>
            <p>More information on the processing and handling of your data including GDPR can be found at
                <a href="https://www.imec-int.com/en/privacy-statement"> https://www.imec-int.com/en/privacy-statement </a>.<br/>
                For any questions or concerns about this survey, do not hesitate to contact us at <span className="mail"
                                                                                                        data-addr="dih-hero"
                                                                                                        data-dom="ilabt.imec.be"/>.
            </p>
            <p>With many thanks in advance!</p>
            <p>The DIH-HERO Consortium </p>

            <NavLink to="/organisation/register"><Button type="primary" size="large">Start the
                Survey</Button></NavLink>
        </div>
    );

}

export {SurveyStart};