let API_ENDPOINT = "/api";

//if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
    //API_ENDPOINT = "http://localhost:5000/api"; // "http://10.10.160.43/api";
    //API_ENDPOINT = "https://services.dih-hero.eu/api";


export async function registerOrganisation(org) {

    return await fetch(API_ENDPOINT + "/organisations",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(org), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json())
        .catch(error => {
            throw Error("Could not create entity");
        });
}

export async function getOrganisation(id) {
    return await fetch(API_ENDPOINT + `/organisations/${id}`)
        .then(res => res.json())
}

export async function finishSurveyForOrganisation(orgId) {
    return await fetch(API_ENDPOINT + `/organisations/${orgId}`, {
        method: 'PATCH',
        body: JSON.stringify({finished: true}),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(res => res.json())
}

export async function registerService(service) {
    return await fetch(API_ENDPOINT + "/services",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(service), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json()).catch(error => {
        throw Error("Could not create entity");
    });
}

export async function deleteService(orgId, serviceId) {
    return await fetch(API_ENDPOINT + `/organisations/${orgId}/services/${serviceId}`,
        {method: 'DELETE'}
    ).then(res => {
        return true;
    }).catch(error => {
        console.log("Could not delete service", error);
        return false;
    })

}

export async function setServiceVisibility(orgId, serviceId, visible) {
    return await fetch(API_ENDPOINT + `/organisations/${orgId}/services/${serviceId}`,
        {
            method: 'PATCH', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({visible: visible})
        },
    ).then(res => res.json())
        .catch(error => {
        console.log("Could not confirm service", error);
        return false;
    })

}

export async function getServicesOfOrganisation(orgId) {
    return await fetch(API_ENDPOINT + `/organisations/${orgId}/services`)
        .then(res => res.json())
}

export async function getServices() {
    return await fetch(API_ENDPOINT + `/results/services.json`)
        .then(res => res.json())
}

export async function getDb(){
    return await fetch(API_ENDPOINT + `/db.json`)
        .then(res =>res.json());
}

export default {
    getServicesOfOrganisation,
    deleteService,
    setServiceVisibility,
    getOrganisation,
    registerService,
    registerOrganisation
}