import {observable, action, computed} from 'mobx';
import {
    getOrganisation,
    getServicesOfOrganisation,
    getServices
} from '../api.js';
import api from '../api.js';
import {getDb} from "../api";

class SurveyStore {

    @observable isLoading = false;
    @observable organisations = observable.map();
    @observable servicesPerOrganisation = observable.map();
    @observable services = observable.array();
    @observable organisationsList = observable.array();

    @observable db = observable.array();
    @observable dbTopics = observable.array();
    @observable dbTypes = observable.array();
    @observable dbCountries = observable.array();

    @action loadAllServices(){
        return getServices()
            .then(action(services => {
                this.services.replace(services);
                this.organisationsList.replace([ ...new Set(services.map(service => service.org_name))].sort());
                return services;
            })).finally(action(() => {
                this.isLoading = false;
            }));
    }

    @action loadDb(){
        return getDb()
            .then(action(db => {
                this.db.replace(db);

                this.dbTopics.replace([...new Set(db.map(entry => entry.topic))]);
                this.dbTypes.replace([...new Set(db.map(entry => entry.type))]);
                this.dbCountries.replace([...new Set(db.flatMap(entry => entry.countries))]);



                return db;
            })).finally(action (()=>{
                this.dbIsLoading = false;
        }));
    }

    @action loadOrganisation(id) {
        const org = this.organisations.get(id);
        if (org)
            return Promise.resolve(org);

        this.isLoading = true;
        return getOrganisation(id)
            .then(action(org => {
                this.organisations.set(id, org);
                return org;
            })).finally(action(() => {
                this.isLoading = false;
            }))
    }

    @action loadServices(id) {

        return getServicesOfOrganisation(id)
            .then(action(services => {
                this.servicesPerOrganisation.set(id, services);
                return services;
            })).finally(action(() => {
                this.isLoading = false;
            }));
    }

    @action deleteService(orgId, id) {
        api.deleteService(orgId, id).then(action("serviceDeleted", (deleted) => {
                if (deleted) {

                    let newValue = this.servicesPerOrganisation.get(orgId).filter(service => service.id !== id);
                    console.log("new value:", newValue)
                    this.servicesPerOrganisation.set(orgId,
                        newValue
                    );

                    return true;
                } else {
                    return false;
                }
            })
        ).catch((err) => {
            console.log("Could not delete service " + id, err);
        });
    }

    @action toggleService(orgId, id) {

        //find array index of service
        const serviceArrayIndex = this.servicesPerOrganisation.get(orgId).findIndex(service => service.id === id);

        console.log(this.servicesPerOrganisation.get(orgId))
        api.setServiceVisibility(orgId, id, !this.servicesPerOrganisation.get(orgId)[serviceArrayIndex].visible)
            .then(action("serviceVisibility", (newService) => {
                this.servicesPerOrganisation.get(orgId)[serviceArrayIndex] = newService;
            })
        ).catch((err) => {
            console.log("Could not confirm service " + id, err);
        });
    }

    @computed get servicesDataSource(){
        return this.services.slice()
    }



}

export default new SurveyStore();